import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import logger from "sabio-debug";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
const _logger = logger.extend("App");

class App extends Component {
  state = {
    currentUser: {
      roles: [],
      userName: "",
      email: ""
    }
  };

  render() {
    _logger("rendering");
    return (
      <React.Fragment>
        <ToastContainer />
        <Container>
          <Row className="justify-content-center">
            <img src="/rbclogo.png" className="" alt="Remotebootcamp.dev" />
          </Row>
          <Row className="justify-content-center">
            <a href="/apihelp">Help Documents</a>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
